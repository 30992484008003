<template>
  <div>
    <FastCheckQuestionForm
      v-if="addNewDataSidebar"
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
    />
    <vx-card>
      <div class="mt-5">
        <vs-row>
          <vs-col
            vs-justify="center"
            vs-align="center"
            vs-lg="2"
            vs-sm="3"
            vs-xs="6"
          >
            <h3 class="font-bold h4">Fast Check Preset category</h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="6"
            vs-sm="5"
            vs-xs="6"
          >
            <h3 class="font-bold h4">Question</h3>
          </vs-col>
          <!-- <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="2"
            vs-sm="2"
            vs-xs="6"
          >
            <h3 class="font-bold h4">Fast Check Preset</h3>
          </vs-col> -->
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="2"
            vs-sm="2"
            vs-xs="6"
          >
            <h3 class="font-bold h4">Age Group</h3>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="left"
            vs-lg="1"
            vs-sm="2"
            vs-xs="12"
          >
            <vs-button class="mr-lg-4 mr-0 mt-sm-0 mt-4" @click="addNewData"
              >Add</vs-button
            >
          </vs-col>
          <vs-divider />
        </vs-row>



        <vs-row :key="index" v-for="(item, index) in fastChecks">

           <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="2"
            vs-sm="3"
            vs-xs="12"
          >
            <p class="h5 mb-2">
              {{ item.fastCheckPreset ? item.fastCheckPreset.name : "" }}
            </p>
          </vs-col>


          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="6"
            vs-sm="6"
            vs-xs="12"
          >
            <p class="h5 mb-2">{{ item.name }}</p>
          </vs-col>
          <!-- <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="4"
            vs-sm="7"
            vs-xs="12"
          >
            <p class="h5 mb-2">
              {{ item.description }}
            </p>
          </vs-col> -->

          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="2"
            vs-sm="3"
            vs-xs="12"
          >
            <p class="h5 mb-2">
              {{ item.ageGroup ? `${item.ageGroup.ageGroup} years` : "" }}
            </p>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="left"
            vs-lg="1"
            vs-sm="2"
            vs-xs="12"
          >
            <a href="#" @click.stop="editData(item)">
              <feather-icon
                icon="EditIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                class="ml-2"
              />
            </a>

            <a href="#" @click.stop="deleteData(item._id)">
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
              />
            </a>
          </vs-col>
          <vs-divider />
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>
<script>
import FastCheckQuestionForm from "./../questions/FastCheckQuestionForm";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { mapActions } from "vuex";

export default {
  components: { FastCheckQuestionForm },
  data() {
    return {
      path: "",
      selected: [],
      fastChecks: [],
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      ageGroups: []
    };
  },
  computed: {},
  methods: {
    ...mapActions("fastCheck", ["deleteFastCheck", "fetchFastChecks"]),
    ...mapActions("fastCheckQuestion", [
      "fetchFastCheckQuestions",
      "deleteFastCheckQuestionById"
    ]),
    ...mapActions("room", ["getAgeGroups"]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    addNewData() {
      this.sidebarData = {};
      // this.toggleDataSidebar(true)
      this.addNewDataSidebar = true;
    },
    deleteData(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.delete(id);
        }
      });
    },
    delete(id) {
      this.$vs.loading();
      this.deleteFastCheckQuestionById(id)
        .then(res => {
          this.$vs.loading.close();
          this.showMessage(
            "Success",
            "Fast Check Question deleted successfully.",
            "success"
          );
          this.getFastCheckQuestion();
        })
        .catch(err => {
          this.$vs.loading.close();
          console.error(err);
        });
    },
    editData(data) {
      this.sidebarData = data;
      this.addNewDataSidebar = true;
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
      this.getFastCheckQuestion();
    },
    async getAgeGroup() {
      await this.getAgeGroups();
    },
    async getFastCheckQuestion() {
      await this.fetchFastCheckQuestions(this.$route.params.id)
        .then(async res => {
          this.$vs.loading.close();
          this.fastChecks = await res.data.data;
        })
        .catch(err => {
          this.$vs.loading.close();
          console.error(err);
        });
    }
  },
  created() {
    this.$vs.loading();
    this.getAgeGroup();
    this.getFastCheckQuestion();
  }
};
</script>
