<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h2>
        {{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} Fast
        Check
      </h2>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="p-6">
        <!-- NAME -->
        <!-- <div>
          <label class="block mb-2">Name</label>
          <vs-input
            v-model="name"
            class="w-full"
            name="name"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div> -->
        <div class="w-full mb-5">
          <div class="select-wrapper">
            <div class="vs-component is-label-placeholder">
              <label class="block mb-2">Age Group</label>
              <vs-select
                placeholder="Age Group"
                autocomplete
                v-model="ageGroup"
                class="w-full"
                v-validate="'required'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in ageGroups"
                  :clearable="false"
                />
              </vs-select>
            </div>
          </div>
          <span class="text-danger text-sm">{{
            errors.first("ageGroup")
          }}</span>
        </div>

        <!-- NAME -->
        <div class="mt-5 w-full">
          <div :class="name ? 'hasValue' : ''">
            <label class="block mb-2">Question</label>
            <vs-textarea v-model="name" />
            <span
              class="text-danger text-sm"
              v-show="errors.has('name')"
              >{{ errors.first("name") }}</span
            >
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid"
        >Submit</vs-button
      >
      <vs-button
        class="vs-button-secondary"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dataId: null,
      name: "",
      description: "",
      ageGroup: "",
      ageGroups: [],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.dataId = this.data._id;
        this.name = this.data.name;
        this.description = this.data.description;
        this.initValues();
      }
    }
  },

  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.name;
    }
  },
  methods: {
    ...mapActions("fastCheckQuestion", [
      "storeFastCheckQuestions",
      "updateFastCheckQuestions"
    ]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    initValues() {
      if (this.data._id) return;
      this.dataId = null;
      this.name = "";
      this.description = "";
    },
    async submitData() {
      await this.$validator.validateAll().then(async result => {
        if (result) {
          const data = {
            fastCheckPresetId: this.$route.params.id,
            name: this.name,
            description: this.description,
            ageGroup: this.ageGroup
          };
          this.$vs.loading();
          if (this.dataId !== null) {
            const params = {
              data: data,
              questionId: this.dataId
            };
            await this.updateFastCheckQuestions(params)
              .then(res => {
                this.$vs.loading.close();
                this.showMessage(
                  "Success",
                  "Fast Check Question updated successfully.",
                  "success"
                );
              })
              .catch(err => {
                this.$vs.loading.close();
                console.error(err);
              });
          } else {
            await this.storeFastCheckQuestions(data)
              .then(res => {
                this.$vs.loading.close();
                this.showMessage(
                  "Success",
                  "Fast Check Question added successfully.",
                  "success"
                );
              })
              .catch(err => {
                this.$vs.loading.close();
                console.error(err);
              });
          }

          this.$emit("refreshData", true);
          this.$emit("closeSidebar");
          this.initValues();
          this.errors.clear();
          this.$nextTick(() => {
            this.$validator.reset();
          });
        }
      });
    }
  },
  components: {
    VuePerfectScrollbar
  },
  beforeMount() {
    this.ageGroups = this.$store.state.room.ageGroups;
  },
  mounted() {
    if (Object.entries(this.data).length === 0) {
      this.initValues();
      this.$validator.reset();
    } else {
      this.dataId = this.data._id;
      this.name = this.data.name;
      this.ageGroup = this.data.ageGroup._id;
      this.description = this.data.description;
    }
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
